export const LOGIN_INIT = "LOGIN_INIT";
export const SPECIALITY_LISTING = "SPECIALITY_LISTING";
export const DOCTOR_DETAIL = "DOCTOR_DETAIL";
export const REMOVE_DOCTOR_DETAIL = "REMOVE_DOCTOR_DETAIL";
export const APPOINTMENT_SLOT = "APPOINTMENT_SLOT";
export const APPOINTMENT_MORE_SLOT = "APPOINTMENT_MORE_SLOT";
export const APPOINTMENT_MORE_SLOT_RESET = "APPOINTMENT_MORE_SLOT_RESET";
export const TREATMENT_LISTING = "TREATMENT_LISTING";
export const BOOK_APPOINMENT_QUESTIONARE = "BOOK_APPOINMENT_QUESTIONARE";
export const DOCTOR_LISTING = "DOCTOR_LISTING";
export const GENERATE_OTP = "GENERATE_OTP";
export const STORE_APPOINMENT_FORM_DATA = "STORE_APPOINMENT_FORM_DATA";
export const REASONFOR_VISIT = "REASONFOR_VISIT";
export const TYPE_OF_APPOINMENT = "TYPE_OF_APPOINMENT";
export const INSURANCE_LIST = "INSURANCE_LIST";
export const BOOKING_RELATED_DATA = "BOOKING_RELATED_DATA";
export const ADD_FAMILY = "ADD_FAMILY";
export const EDIT_FAMILY = "EDIT_FAMILY";
export const FETCH_CLIENTDETAILS = "FETCH_CLIENTDETAILS";
export const FETCH_APPOINTMENT_HISTORY="FETCH_APPOINTMENT_HISTORY";
export const FETCH_SELECTED_APPOINTMENT_DETAILS="FETCH_SELECTED_APPOINTMENT_DETAILS";
export const CHECK_CONSULTATION = "CHECK_CONSULTATION";
export const FETCH_CONSULTATION = "FETCH_CONSULTATION";
export const BOOKING_CONFIRMATION = "BOOKING_CONFIRMATION";
export const BOOKING_UPDATE_CONFIRMATION = "BOOKING_UPDATE_CONFIRMATION";
export const LOGIN_SUCCESS_ACTION = "LOGIN_SUCCESS_ACTION";
export const LOG_OUT_ACTION = "LOG_OUT_ACTION";
export const SIGNUP_SUCCESS_ACTION = "SIGNUP_SUCCESS_ACTION";
export const FETCH_FAMILY_MEMBERS = "FETCH_FAMILY_MEMBERS";
export const FETCH_PATIENT_MEDICAL_DATA = "FETCH_PATIENT_MEDICAL_DATA";
export const FETCH_UPLOADED_DATA = "FETCH_UPLOADED_DATA";
export const REQUEST_BOOKING_CONFIRM = "REQUEST_BOOKING_CONFIRM";
export const GOTO_DASHBOARD = "GOTO_DASHBOARD";
export const FETCH_PATIENT_MEDICAL_DATA_INIT_ACTION =
  "FETCH_PATIENT_MEDICAL_DATA_INIT_ACTION";
export const FETCH_PATIENT_MEDICAL_DATA_FAIL_ACTION =
  "FETCH_PATIENT_MEDICAL_DATA_FAIL_ACTION";
export const RESET_BOOKING = 'RESET_BOOKING';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';

export const DASHBOARD_DATA_PATIENT = "DASHBOARD_DATA_PATIENT";

export const FETCH_COUNTRYDATA="FETCH_COUNTRYDATA";

export const INTERBRANCH_MODAL="INTERBRANCH_MODAL";
export const INTERBRANCH_ADMIN_DASHBOARD="INTERBRANCH_ADMIN_DASHBOARD";
export const INTERBRANCH_ADMIN_DETAILED="INTERBRANCH_ADMIN_DETAILED";
export const INTERBRANCH_ADMIN_DASHBOARD_SELECTED="INTERBRANCH_ADMIN_DASHBOARD_SELECTED";
export const INTERBRANCH_ADMIN_DASHBOARD_SELECTED_v2="INTERBRANCH_ADMIN_DASHBOARD_SELECTED_v2"
export const INTERBRANCH_ADMIN_DETAILED_SELECTED="INTERBRANCH_ADMIN_DETAILED_SELECTED";
export const INTERBRANCH_ADMIN_CONSOLIDATED="INTERBRANCH_ADMIN_CONSOLIDATED";
export const MANAGE_HOSPITALS_LIST="MANAGE_HOSPITALS_LIST";


export const INVESTIGATION_HEAD = "Investigation"
export const MEDICINE_HEAD = "Medicine"
export const UPDATE_REDUX_PRESCRIPTION = "UPDATE_REDUX_PRESCRIPTION"
export const SET_SUBMISSION_DATA_PRESCRIPTION = "SET_SUBMISSION_DATA_PRESCRIPTION"
export const UPDATE_INVESTIGATION_TABLE_DATA = "UPDATE_INVESTIGATION_TABLE_DATA"
export const UPDATE_MEDICINE_TABLE_DATA = "UPDATE_MEDICINE_TABLE_DATA"

export const SET_SELECTED_DEPARTMENT = "SET_SELECTED_DEPARTMENT"
export const SET_SELECTED_DOCTORS = "SET_SELECTED_DOCTORS"
export const SET_DOCTORS = "SET_DOCTORS"
export const SET_CR_DASHBOARD= "SET_CR_DASHBOARD"
export const UPDATE_USER = "UPDATE_USER";

export const CLEAR_PRESCRIPTION="CLEAR_PRESCRIPTION"
export const REDUX_LOADING="REDUX_LOADING"
export const MANAGE_SESSION = "MANAGE_SESSION"
export const CLEAR_INTERBRANCH_ADMIN = "CLEAR_INTERBRANCH_ADMIN"

export const RPM_ADD_PATIENT_ALLERGIE = "RPM_ADD_PATIENT_ALLERGIE"
export const RPM_ADD_PATIENT_MEDICATION = "RPM_ADD_PATIENT_MEDICATION"
export const ALLERGY_REDUX_STATE = "ALLERGY_REDUX_STATE"

export const CLEAR_RPM_ADMIN = "CLEAR_RPM_ADMIN"

export const ESCALATE_TO_HCP = "ESCALATE_TO_HCP"
export const INSTRUCT_NURSE = "INSTRUCT_NURSE"
export const SEND_AMBULANCE = "SEND_AMBULANCE"
export const FETCH_PATIENT_PROFILE = "FETCH_PATIENT_PROFILE"
export const FETCH_PATIENTS_VITALS = "FETCH_PATIENTS_VITALS"
export const FETCH_PATIENTS_VITALS_SETTINGS = "FETCH_PATIENTS_VITALS_SETTINGS"
export const FETCH_PATIENTS_VITALS_HISTORY = "FETCH_PATIENTS_VITALS_HISTORY"
export const FETCH_PATIENTS_HEALTH_DATA = "FETCH_PATIENTS_HEALTH_DATA"
export const FETCH_PATIENTS_SINGLE_VITALS = "FETCH_PATIENTS_SINGLE_VITALS"
export const FETCH_PATIENTS_CONSULTATION_HISTORY = "FETCH_PATIENTS_CONSULTATION_HISTORY"
export const FETCH_PATIENTS_CONSULTATION_DETAILS = "FETCH_PATIENTS_CONSULTATION_DETAILS"
export const FETCH_PATIENTS_LIST = "FETCH_PATIENTS_LIST"
export const SET_SELECTED_CLINIC = 'SET_SELECTED_CLINIC';
export const setSelectedClinic = (clinicName) => ({
  type: SET_SELECTED_CLINIC,
  payload: clinicName
});

export const CLEAR_RPM_PATIENT_VITALS = "CLEAR_RPM_PATIENT_VITALS"

export const UPDATE_CONSULTATION_LIFESTYLE = "UPDATE_CONSULTATION_LIFESTYLE"
export const UPDATE_CONSULTATION_PRE_EXISTING_CONDITION = "UPDATE_CONSULTATION_PRE_EXISTING_CONDITION"
export const UPDATE_CONSULTATION_NOTE = "UPDATE_CONSULTATION_NOTE"

export const UPDATE_CONSULTATION_ADD_MEDICATION = "UPDATE_CONSULTATION_ADD_MEDICATION"
export const UPDATE_CONSULTATION_EDIT_MEDICATION = "UPDATE_CONSULTATION_EDIT_MEDICATION"
export const UPDATE_CONSULTATION_REMOVE_MEDICATION = "UPDATE_CONSULTATION_REMOVE_MEDICATION"

export const UPDATE_CONSULTATION_ADD_ALLERGY = "UPDATE_CONSULTATION_ADD_ALLERGY"
export const UPDATE_CONSULTATION_EDIT_ALLERGY = "UPDATE_CONSULTATION_EDIT_ALLERGY"
export const UPDATE_CONSULTATION_REMOVE_ALLERGY = "UPDATE_CONSULTATION_REMOVE_ALLERGY"

export const UPDATE_CONSULTATION_ADD_TEST = "UPDATE_CONSULTATION_ADD_TEST"
export const UPDATE_CONSULTATION_EDIT_TEST = "UPDATE_CONSULTATION_EDIT_TEST"
export const UPDATE_CONSULTATION_REMOVE_TEST = "UPDATE_CONSULTATION_REMOVE_TEST"

export const UPDATE_CONSULTATION_ADD_PRESCRIPTION = "UPDATE_CONSULTATION_ADD_PRESCRIPTION"
export const UPDATE_CONSULTATION_EDIT_PRESCRIPTION = "UPDATE_CONSULTATION_EDIT_PRESCRIPTION"
export const UPDATE_CONSULTATION_REMOVE_PRESCRIPTION = "UPDATE_CONSULTATION_REMOVE_PRESCRIPTION"
//export const FETCH_SELECTED_APPOINTMENT_DETAILS = "FETCH_SELECTED_APPOINTMENT_DETAILS"

export const ADD_DEFAULT_PRE_EXISTING = "ADD_DEFAULT_PRE_EXISTING"

export const UPDATE_MANAGE_PRE_SUBMISSION_DATA = "UPDATE_MANAGE_PRE_SUBMISSION_DATA";

export const Add_SYMPTOM_PRESCRIPTION = "Add_SYMPTOM_PRESCRIPTION";
export const Add_MEDICAL_HISTORY_PRESCRIPTION = "Add_MEDICAL_HISTORY_PRESCRIPTION";
export const Add_EXAMINATION_PRESCRIPTION = "Add_EXAMINATION_PRESCRIPTION";
export const Add_DIAGNOSIS_PRESCRIPTION = "Add_DIAGNOSIS_PRESCRIPTION";
export const Add_INVESTIGATION_PRESCRIPTION = "Add_INVESTIGATION_PRESCRIPTION";
export const Add_INSTRUCTION_PRESCRIPTION = "Add_INSTRUCTION_PRESCRIPTION";
export const Add_MEDICATION_PRESCRIPTION = "Add_MEDICATION_PRESCRIPTION";
export const Add_NON_VACC_REASON_PRESCRIPTION = "Add_NON_VACC_REASON_PRESCRIPTION";

export const TOGGLE_SELECT_SYMPTOM_PRESCRIPTION = "TOGGLE_SELECT_SYMPTOM_PRESCRIPTION";
export const TOGGLE_SELECT_MEDICAL_HISTORY_PRESCRIPTION = "TOGGLE_SELECT_MEDICAL_HISTORY_PRESCRIPTION";
export const TOGGLE_SELECT_EXAMONATION_PRESCRIPTION = "TOGGLE_SELECT_EXAMONATION_PRESCRIPTION";
export const TOGGLE_SELECT_DIAGNOSIS_PRESCRIPTION = "TOGGLE_SELECT_DIAGNOSIS_PRESCRIPTION";
export const TOGGLE_SELECT_INVESTIGATION_PRESCRIPTION = "TOGGLE_SELECT_INVESTIGATION_PRESCRIPTION";
export const TOGGLE_SELECT_INSTRUCTION_PRESCRIPTION = "TOGGLE_SELECT_INSTRUCTION_PRESCRIPTION";
export const TOGGLE_SELECT_MEDICATION_PRESCRIPTION = "TOGGLE_SELECT_MEDICATION_PRESCRIPTION";
export const LOAD_TEMPLATE_IN_MANAGE_PRECRIPTION = "LOAD_TEMPLATE_IN_MANAGE_PRECRIPTION";

